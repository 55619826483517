import { useHistory , useLocation } from 'react-router-dom'; 
import React, { useState, useEffect } from 'react';
const AthenaRedirect = () => {
  const history = useHistory();


  const location = useLocation();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log("key values : " ,searchParams )
  }, []);


  return (
    <div className="relative flex items-center justify-center min-h-screen ">

      <div className="text-center">
        <h1
          className="text-6xl font-bold mb-6"
        
        >
          Loading Application...
        </h1>

      </div>
    </div>
  );
};

export default AthenaRedirect;
