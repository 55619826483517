import React from 'react';
import ReactIframe from 'react-iframe';

function IframeComponent() {
  return (
    <ReactIframe
    url="https://clinicalnotes.farz.ai/live-record"
      width="50%"
      height="500px"
      className="custom-iframe"
      style={{
        border: '2px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
      }}
    />
  );
}

export default IframeComponent;
