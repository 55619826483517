import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
function Prompt() {
    const history = useHistory();
  const [prompts, setPrompts] = useState([]);
  const [activePromptId, setActivePromptId] = useState(null);

  const Dytoken = localStorage.getItem('token');
  useEffect(() => {

    // Fetch prompts from the API
    const fetchPrompts = async () => {
      try {
        const response = await fetch('https://clinicalnotesapp.azurewebsites.net/custom_prompt/', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${Dytoken}`,  'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
          }
        });
        const data = await response.json();
        setPrompts(data);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    };

    // Fetch active prompt
    const fetchActivePrompt = async () => {
      try {
        const response = await fetch('https://clinicalnotesapp.azurewebsites.net/user_prompt_settings/get_user_prompt_setting/', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${Dytoken}`, 'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
          }
        });
        const data = await response.json();
        setActivePromptId(data.active_prompt_id);
      } catch (error) {
        console.error('Error fetching active prompt:', error);
      }
    };

    fetchPrompts();
    fetchActivePrompt();
  }, []);

  const handleNewPrompt = () => {
    Swal.fire({
      title: 'Create New Prompt',
      html: `<input type="text" id="title" class="swal2-input w-1/2" placeholder="Title">
      <div class="flex justify-center">     
      <textarea id="prompt" class="swal2-textarea" style="width: 90%; height: 15rem; box-sizing: border-box;" placeholder="Enter your prompt"></textarea>
        </div>`,
      confirmButtonText: 'Submit',
      focusConfirm: false,
      showCloseButton: true,  // Added to show the close button
      customClass: {
        popup: 'w-full max-w-3xl p-4'  // Tailwind classes to set modal width and padding
      },
      preConfirm: () => {
        const title = Swal.getPopup().querySelector('#title').value;
        const prompt = Swal.getPopup().querySelector('#prompt').value;
        if (!title || !prompt) {
          Swal.showValidationMessage(`Please enter both title and prompt`);
        }
        return { title: title, prompt: prompt };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { title, prompt } = result.value;
        createNewPrompt(title, prompt);
      }
    });
  };

  const createNewPrompt = async (title, prompt) => {
    try {
      const response = await fetch('https://clinicalnotesapp.azurewebsites.net/custom_prompt/', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        'Authorization': `Bearer ${Dytoken}`,
         'Content-Type': 'application/json',
          'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
        },
        body: JSON.stringify({
          title: title,
          prompt: prompt
        })
      });
      const data = await response.json();
      setPrompts([...prompts, data]);
      Swal.fire('Success', 'Prompt created successfully', 'success');
    } catch (error) {
      console.error('Error creating prompt:', error);
      Swal.fire('Error', 'There was an error creating the prompt', 'error');
    }
  };

  const handleEditPrompt = (id, currentTitle, currentPrompt) => {
    Swal.fire({
      title: 'Edit Prompt',
      html: `
        <input type="text" id="title" class="swal2-input w-1/2" value="${currentTitle}" placeholder="Title">
        <div class="flex justify-center">
          <textarea id="prompt" class="swal2-textarea" style="width: 90%; height: 15rem; box-sizing: border-box;" placeholder="Enter your prompt">${currentPrompt}</textarea>
        </div>
      `,
      confirmButtonText: 'Update',
      focusConfirm: false,
      showCloseButton: true,  // Added to show the close button
      customClass: {
        popup: 'w-full max-w-3xl p-4'  // Tailwind classes to set modal width and padding
      },
      preConfirm: () => {
        const title = Swal.getPopup().querySelector('#title').value.trim();
        const prompt = Swal.getPopup().querySelector('#prompt').value.trim();
        if (!title || !prompt) {
          Swal.showValidationMessage('Please enter both title and prompt');
          return false; // Return false to prevent the confirmation and allow the user to correct their input
        }
        return { id: id, title: title, prompt: prompt };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { id, title, prompt } = result.value;
        updatePrompt(id, title, prompt);
      }
    });
  };
  
  
  
  
  const updatePrompt = async (id, title, prompt) => {
    // console.log('Attempting to update prompt with id:', id);
    // console.log('Title:', title);
    // console.log('Prompt:', prompt);

    try {
      const response = await fetch('https://clinicalnotesapp.azurewebsites.net/custom_prompt/update/', {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
        'Authorization': `Bearer ${Dytoken}`,
          'Content-Type': 'application/json',
          'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
        },
        body: JSON.stringify({
          id: id,  // Ensure this is the correct ID
          title: title,
          prompt: prompt
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update the prompt: ${errorText}`);
      }

      const updatedPrompt = await response.json();

      setPrompts((prevPrompts) =>
        prevPrompts.map((p) => (p.id === id ? updatedPrompt : p))
      );

      Swal.fire('Success', 'Prompt updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prompt:', error);
      Swal.fire('Error', `There was an error updating the prompt: ${error.message}`, 'error');
    }
  };

  const deletePrompt = async (id) => {
    try {
      const response = await fetch(`https://clinicalnotesapp.azurewebsites.net/custom_prompt/delete/?id=${id}`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
        'Authorization': `Bearer ${Dytoken}`,  'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to delete the prompt: ${errorText}`);
      }

      const result = await response.json();
      // console.log(result.message);

      setPrompts((prevPrompts) => prevPrompts.filter((p) => p.id !== id));

      Swal.fire('Deleted!', 'Your prompt has been deleted.', 'success');
    } catch (error) {
      console.error('Error deleting prompt:', error);
      Swal.fire('Error', `There was an error deleting the prompt: ${error.message}`, 'error');
    }
  };

  const setActivePrompt = async (id, promptType) => {
    try {
      const response = await fetch('https://clinicalnotesapp.azurewebsites.net/user_prompt_settings/update/', {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
        'Authorization': `Bearer ${Dytoken}`,  'Content-Type': 'application/json',
          'X-CSRFToken': 'QD2lLaVeS9aWBSA4gArNZidrlgVHnTu00KT9qJFEPHLixR89LOPknO98XAj551Q8'
        },
        body: JSON.stringify({
          prompt_id: id,
          prompt_type: promptType
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to set active prompt: ${errorText}`);
      }

      const data = await response.json();
      setActivePromptId(data.active_prompt_id);


      Swal.fire({
        title: "Success!",
        text: "Prompt set as active successfully",
        icon: null, // Removes the success icon
        customClass: {
          popup: 'max-w-xs', // Tailwind class to set the max-width of the popup
          confirmButton: 'bg-[#f2911b] text-white py-2 px-4 rounded hover:bg-[#d07b15]' // Tailwind classes for the button
        },
        buttonsStyling: false, // Disable default button styling
        confirmButtonText: "OK"
      });
    } catch (error) {
      console.error('Error setting active prompt:', error);
      Swal.fire('Error', `There was an error setting the active prompt: ${error.message}`, 'error');
    }
  };
  const handleBack = () => {
    history.push("/setting");
  };
  
  
  // Sort prompts to have the active one on top
  const sortedPrompts = [...prompts].sort((a, b) => (a.id === activePromptId ? -1 : b.id === activePromptId ? 1 : 0));

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-8">
         <button
        onClick={handleBack}
        className=" bg-white text-black py-2 px-4 rounded hover:bg-gray-300"
      >
        Back
      </button>
      <div className="w-full h-full p-4 md:p-8">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <h1 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-0">My Prompts</h1>
          <button
            className="bg-[#f2911b]  text-white py-2 px-4 md:px-6 rounded-full shadow border-2 hover:bg-transparent hover:text-[#f2911b] hover:border-[#f2911b]  focus:outline-none"
            onClick={handleNewPrompt}
          >
            + New Prompts
          </button>
        </div>

        {/* Render prompts */}
        {sortedPrompts.length > 0 ? (
          sortedPrompts.map((prompt) => (
            <div
              key={prompt.id}
              className={`p-4 md:p-6 bg-white rounded-3xl shadow-lg w-full h-auto mb-4 ${
                prompt.id === activePromptId ? 'border-4 border-blue-500' : ''
              }`}
            >
              <div className="flex justify-between items-start md:items-center mb-4">
                <h2 className="font-semibold text-lg text-gray-800">{prompt.title}</h2>
                <div className="flex items-center space-x-4">
                  <span className="text-sm px-3 py-1 rounded-full bg-gray-200 text-gray-600">
                    {prompt.prompt_type}
                  </span>
                </div>
              </div>
              <div className="bg-gray-100 p-4 rounded-2xl">
                <p className="text-gray-600">{prompt.prompt}</p>
              </div>
              <div className="flex space-x-4 mt-4">
                {prompt.prompt_type === 'custom' && (
                  <>
                    <button
                      className="flex items-center justify-center bg-gray-200 text-gray-600 p-2 rounded-full hover:bg-gray-300 transition"
                      onClick={() => handleEditPrompt(prompt.id, prompt.title, prompt.prompt)}
                    >
                      <FaPencilAlt className="w-5 h-5" />
                    </button>
                    <button
                      className="flex items-center justify-center bg-red-200 text-red-600 p-2 rounded-full hover:bg-red-300 transition"
                      onClick={() => deletePrompt(prompt.id)}
                    >
                      <FaTrashAlt className="w-5 h-5" />
                    </button>
                  </>
                )}
                <button
                  className={`flex items-center justify-center p-2 rounded-full transition ${
                    prompt.id === activePromptId ? 'bg-green-200 text-green-600' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                  }`}
                  onClick={() => setActivePrompt(prompt.id, prompt.prompt_type)}
                >
                  {prompt.id === activePromptId ? 'Active' : 'Set Active'}
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No prompts available.</p>
        )}
      </div>
    </div>
  );
}

export default Prompt;
