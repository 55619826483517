// src/components/History.js
import React from 'react';

const History = () => {
    
    return (
        <div>
            <h1 className="text-2xl font-bold mb-8">History</h1>
            <p>This is the History page content.</p>
        </div>
    );
};

export default History;
