import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from '../../Assets/images/loading.svg'
const UserProfile = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const professionInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    googleProfileURL: localStorage.getItem("GoogleProfileURL") || "",
    email: localStorage.getItem("Email") || "",
    username: localStorage.getItem("Username") || "",
    isPremium: false,
    profession: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetailResponse = await fetch(
          "https://clinicalnotesapp.azurewebsites.net/user_detail/",
          {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + token,
              "X-CSRFToken": "wwnq3j4EIwHLEb94hA6fQmuIaZl6PAZlgpCEZyyt95CYtguCq636Gehh31HVdnOA",
            },
          }
        );

        const userProfessionResponse = await fetch(
          "https://clinicalnotesapp.azurewebsites.net/user_profession",
          {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + token,
              "X-CSRFToken": "wwnq3j4EIwHLEb94hA6fQmuIaZl6PAZlgpCEZyyt95CYtguCq636Gehh31HVdnOA",
            },
          }
        );

        if (!userDetailResponse.ok || !userProfessionResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const userDetails = await userDetailResponse.json();
        const userProfession = await userProfessionResponse.json();

        setProfileData({
          googleProfileURL:
            userDetails.google_picture_url || localStorage.getItem("GoogleProfileURL") || "",
          email: userDetails.email || localStorage.getItem("Email") || "",
          username: userDetails.username || localStorage.getItem("Username") || "",
          isPremium: userDetails.is_premium,
          profession: userProfession.profession || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [token]);

  const handleProfessionChange = (event) => {
    setProfileData({ ...profileData, profession: event.target.value });
  };

  const updateProfession = async () => {
    try {
      const response = await fetch(
        "https://clinicalnotesapp.azurewebsites.net/user_profession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + token,
            "X-CSRFToken": "wwnq3j4EIwHLEb94hA6fQmuIaZl6PAZlgpCEZyyt95CYtguCq636Gehh31HVdnOA",
          },
          body: JSON.stringify({ profession: profileData.profession }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update profession");
      }
      const data = await response.json();
      setProfileData((prevData) => ({
        ...prevData,
        profession: data.profession,
      }));
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profession:", error);
    }
  };

  const ChangePassword = () => {
    history.push("/forgot-password");
  };
  const PromptSetting = () => {
    history.push("/prompt");
  };
  


  const getUsernameInitial = (username) => {
    console.log("Username:", username);
    return username && username.length > 0 ? username.charAt(0).toUpperCase() : "";
  };
  

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (professionInputRef.current) {
        professionInputRef.current.focus();
      }
    }, 0);
  };

  if (loading) {
    return  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <div style={{ textAlign: 'center' }}>
      <div>Loading...</div>
      <div>
        <img src={Loading} alt="Loading" />
      </div>
    </div>
  </div>;
  }

  return (
    <div className="animate-slideInRight min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <div className="flex justify-between items-center border-b pb-4 mb-4">
          <h2 className="text-xl font-bold">Profile</h2>
          <span
            className={`px-4 py-2 text-sm font-bold rounded-full ${
              profileData.isPremium ? "bg-[#F2911B] text-white" : "bg-gray-100 text-gray-800"
            }`}
          >
            {profileData.isPremium ? (
              <span>
                Enterprise <i className="fas fa-crown"></i>
              </span>
            ) : (
              "Standard"
            )}
          </span>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8">
        <div
  className="relative mb-4 lg:mb-0 border-2 rounded-full"
  style={{ borderColor: "rgb(243, 156, 18)" }}
>
  {profileData.googleProfileURL ? (
    <img
      className="w-24 h-24 rounded-full object-cover"
      src={profileData.googleProfileURL}
      alt="User profile"
    />
  ) : (
    <div className="w-24 h-24 rounded-full bg-[#F2911B] flex items-center justify-center text-3xl font-bold text-white">
      {getUsernameInitial(profileData.username)}
    </div>
  )}
</div>


          <div className="flex-1 space-y-4 w-full">
            <div>
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                value={profileData.email}
                disabled={!isEditing}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Username</label>
              <input
                type="text"
                value={profileData.username}
                disabled={!isEditing}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Profession</label>
              <div className="flex items-center space-x-4">
                <input
                  ref={professionInputRef}
                  type="text"
                  value={profileData.profession}
                  onChange={handleProfessionChange}
                  disabled={!isEditing}
                  className="w-full px-4 py-2 border rounded-md"
                />
                {isEditing ? (
                  <button
                    onClick={updateProfession}
                    className="px-4 py-2 bg-[#F2911B] text-white rounded-md hover:border-[#F2911B] hover:text-[#F2911B] hover:bg-transparent border"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={handleEditClick}
                    className="px-4 py-2 bg-red-50 text-red-600 rounded-md hover:border-red-600 hover:text-red-600 hover:bg-transparent border"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <div>
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                value="**********"
                disabled={!isEditing}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-end lg:space-x-4 space-y-4 lg:space-y-0 mt-4">
            <button
    onClick={PromptSetting}
    className="px-4 py-2 bg-[#F2911B] text-white rounded-md hover:bg-transparent border hover:border-[#f2911b] hover:text-[#f2911b] transitionr"
  >
    Prompt Setting
  </button>
  <button
    onClick={ChangePassword}
    className="px-4 py-2 bg-red-50 text-red-600 rounded-md hover:border-red-600 hover:text-red-600 hover:bg-transparent border"
  >
    Change Password
  </button>
  <button
    onClick={() => history.push('/patient-dashboard')}
    className=" px-4 py-2 bg-[#F2911B] text-white rounded-md hover:bg-transparent border hover:border-[#f2911b] hover:text-[#f2911b] transition"
  >
    Patients
  </button>
</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
