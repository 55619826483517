import React from 'react';
import StripeProvider from './StripeProvider';
import PaymentForm from './Payment';  // Updated to point to the combined PaymentForm

function App() {
  return (
    <StripeProvider>
      {/* <h1>Stripe Payment Integration</h1> */}
      <PaymentForm planId="price_1Psg2IRvFy2hSo8sHExhVyGv" />  {/* Pass the planId prop */}
    </StripeProvider>
  );
}

export default App;
