import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faCircleCheck, faCircleXmark, faClock } from "@fortawesome/free-solid-svg-icons";

const App = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Main Container */}
      <div className="w-full max-w-7xl mx-auto bg-white p-10 rounded-lg shadow-md">
        
        {/* Header Section */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-4xl font-bold text-gray-900">ADAMS, Joe</h1>
            <p className="text-lg text-gray-500">May 17, 1972 (51Y)</p>
          </div>
          <div className="flex items-center border border-gray-300 rounded-md px-6 py-4 shadow-sm bg-gray-50">
            <p className="text-lg mr-6 text-gray-600">02/08/2024 1:38 PM</p>
            <p className="flex items-center space-x-2 text-gray-600">
              <FontAwesomeIcon icon={faClock} className="text-red-500 h-6 w-6" />
              <span className="text-lg">04:50</span>
              <span className="ml-4 text-lg">Lee, Robin</span>
            </p>
          </div>
        </div>

        {/* Conversation Summary */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <h2 className="text-xl font-semibold text-pink-600">Conversation Summary</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-purple-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">
              The patient, currently going through a divorce and experiencing lifestyle changes, reported feeling as if their heart was skipping a beat, shortness of breath, and getting winded quickly, especially after heavy drinking...
            </p>
          </div>
        </section>

        {/* HPI Section */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <h2 className="text-xl font-semibold text-pink-600">HPI</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-purple-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">
              Patient reported feeling of heart skipping a beat, shortness of breath, and getting winded quickly. Symptoms started over the past weekend. Patient reported heavy drinking over the weekend. Patient is currently going through a divorce, staying at a friend's place, not sleeping well, and has gained weight. Patient reported drinking about a 6 pack most nights.
            </p>
          </div>
        </section>

        {/* Current Medication */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleCheck} className="text-blue-500" />
              <h2 className="text-xl font-semibold text-gray-700">Current Medication</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-blue-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">Not specified</p>
          </div>
        </section>

        {/* Medical History */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleXmark} className="text-gray-400" />
              <h2 className="text-xl font-semibold text-gray-700">Medical History</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-500">Not discussed</p>
          </div>
        </section>

        {/* Surgical History */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleXmark} className="text-gray-400" />
              <h2 className="text-xl font-semibold text-gray-700">Surgical History</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-500">Not discussed</p>
          </div>
        </section>

        {/* Hospitalization History */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleXmark} className="text-gray-400" />
              <h2 className="text-xl font-semibold text-gray-700">Hospitalization History</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-500">Not discussed</p>
          </div>
        </section>

        {/* Family History */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleXmark} className="text-gray-400" />
              <h2 className="text-xl font-semibold text-gray-700">Family History</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-500">Not discussed</p>
          </div>
        </section>

        {/* Social History */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleCheck} className="text-blue-500" />
              <h2 className="text-xl font-semibold text-gray-700">Social History</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-purple-50 p-6 rounded-lg border border-gray-300 mt-2">
            <ul className="list-disc list-inside text-md text-gray-800">
              <li>Alcohol consumption: Heavy, about a 6 pack most nights</li>
              <li>Living situation: Staying at a friend's place</li>
              <li>Sleep: Not sleeping well</li>
              <li>Stress: Going through a divorce</li>
            </ul>
          </div>
        </section>

        {/* Vitals */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCircleCheck} className="text-red-500" />
              <h2 className="text-xl font-semibold text-gray-700">Vitals</h2>
            </div>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-pink-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">Heart Rate: 130</p>
          </div>
        </section>

        {/* Examination */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-700">Examination</h2>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-pink-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">Heart rate: Irregular, around 130 beats per minute</p>
            <p className="text-md text-gray-800">EKG: Confirmed atrial fibrillation with a rapid ventricular rate</p>
          </div>
        </section>

        {/* Treatment Plan */}
        <section className="mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-pink-600">Treatment Plan</h2>
            <FontAwesomeIcon icon={faMicrophone} className="text-gray-500 cursor-pointer bg-gray-100 p-3 rounded-full hover:bg-gray-200" />
          </div>
          <div className="bg-purple-50 p-6 rounded-lg border border-gray-300 mt-2">
            <p className="text-md text-gray-800">
              I48.91: Goals: Regulate heart rhythm, prevent stroke. Prescribed apixaban and diltiazem, ordered several lab tests, arranged for an echocardiogram. Specific instructions: Abstain from alcohol. Monitoring and Evaluation: Follow-up appointment after echocardiogram. Patient Education: Explained the diagnosis and the need to abstain from alcohol.
            </p>
          </div>
        </section>

        {/* Footer */}
        <div className="text-md text-gray-400 mt-8">
          <p>Coordination of Care: N/A</p>
        </div>
      </div>
    </div>
  );
};

export default App;
