import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, Switch , Redirect } from 'react-router-dom';
import Registration from './components/Private/Register.jsx';
import OtpVerification from './components/Secure/OtpVerification.jsx';
import ForgotPassword from './components/Secure/ForgotPassword.jsx';
import Dashboard from './components/Content/Dashboard.jsx';
import History from './components/Content/History.jsx';
import Layout from './components/Layout/Layout.jsx';
import Login from './components/Private/Login.jsx';
import NewPassword from './components/Secure/NewPassword.jsx';
import LiveRecord from './components/Screen/RecordStream.jsx';
import LiveRecordTemp from './components/Screen/RecordStreamTemp.jsx';
import LiveRecordTest from './components/Screen/RecordStreamTest.jsx';
import Gemini from './components/Screen/GeminiStream.jsx';
import HistoryDetails from './components/History/HistoryDetails.jsx';
import Setting from './components/Data/UserProfile.jsx';
import PricingTable from './components/Expo/Pricing.jsx';
import Contact from './components/Expo/Contact.jsx';
import SharedHistoryWrapper from './components/History/SharedHistoryWrapper.jsx'; 
import './index.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientDashboard from './components/Client/PatientDashboard.jsx';
import SinglePatient from './components/Client/SinglePatient.jsx';
import Payment from './components/Expo/PaymentIntegration.jsx';
import ThankYou from './components/Expo/ThankYou.jsx';
import Submitted from './components/Expo/Submitted.jsx';
import IframeComponent from './components/iframe.jsx';
import Prompt from './components/Prompt/Prompt.jsx';
import Newdesign from './components/newdesign.jsx'
import FhirCallback from './components/Shared/FhirCallback.jsx';
import FhirRedirect from './components/Shared/FhirRedirect.jsx';
import AthenaRedirect from './components/Private/Athena.jsx';
import AthenaRecordScreen from './components/Screen/AthenaRecordStream.jsx';
import PolyDiarize from './components/Screen/PolyDiarize.jsx';
import MonoDiarize from './components/Screen/MonoDiarize.jsx';
import TranslaDiarize from './components/Screen/TranslaDiarize.jsx';
import WaveScreen from './components/Screen/WaveScreen.jsx'
import WaveTest from './components/Screen/WaveTest.jsx'
import Translation from './components/Screen/Translation.jsx'
export const TokenContext = createContext(null);
function App() {
  const token = localStorage.getItem("token");
  return (
    <>
      <ToastContainer />
      <TokenContext.Provider value={token}>
        <Router basename={process.env.PUBLIC_URL || '/'}>
          <Switch>
           {/* If token exists, redirect to /live-record, otherwise show login */}
          
            <Route path="/" exact component={Login} />
            <Route path="/athena-redirect" exact component={AthenaRedirect} />
            <Route path="/register" exact component={Registration} />
            <Route path="/otp-verification" component={OtpVerification} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/new-password" component={NewPassword} />
            <Route path="/pricing" component={PricingTable} />
            <Route path="/contact" component={Contact} />
            <Route path="/patient-dashboard" component={PatientDashboard} />
            <Route path="/patient/:id" component={SinglePatient} />
            <Route path="/payment" component={Payment} />
            <Route path="/thankyou" component={ThankYou} />
            <Route path="/submitted" component={Submitted} />
            <Route path="/iframe" component={IframeComponent} /> 
            {/* <Route path="/prompt" component={Prompt} />  */}
           
            {/**Routing that take Sidebar with itself Start */}
            <Route path="/translation">
              <Layout>
                <Translation />
              </Layout>
            </Route>

            <Route path="/wavetest">
              <Layout>
                <WaveTest />
              </Layout>
            </Route>

            <Route path="/dashboard">
              <Layout>
                <Dashboard />
              </Layout>
            </Route>
            
            <Route path="/history">
              <Layout>
                <History />
              </Layout>
            </Route>
            <Route path="/live-record">
              <Layout>
                <LiveRecord />
              </Layout>
            </Route>



            <Route path="/polydiarize">
              <Layout>
                <PolyDiarize />
              </Layout>
            </Route>
            <Route path="/monodiarize">
              <Layout>
                <MonoDiarize />
              </Layout>
            </Route>
            <Route path="/transladiarize">
              <Layout>
                <TranslaDiarize />
              </Layout>
            </Route>
            <Route path="/wavescreen">
              <Layout>
                <WaveScreen />
              </Layout>
            </Route>



            <Route path="/athena-record">
          
                <AthenaRecordScreen />
             
            </Route>

            

            <Route path="/live-record-test">
              <Layout>
                <LiveRecordTest />
              </Layout>
            </Route>
            <Route path="/live-record-temp">
              <Layout>
                <LiveRecordTemp />
              </Layout>
            </Route>
            <Route path="/athenaone-fhir-app">
                <FhirCallback />
        
            </Route>
            <Route path="/athenaone-fhir">
                <FhirRedirect />
          
            </Route>
            {/* <Route path="/live-record-test">
              <Layout>
                <LiveRecordTest />
              </Layout>
            </Route> */}
            <Route path="/gemini">
              <Layout>
                <Gemini />
              </Layout>
            </Route>
            <Route path="/conversation/:id">
              <Layout>
                <HistoryDetails />
              </Layout>
            </Route>
            
            <Route path="/setting">
              <Layout>
                <Setting />
              </Layout>
            </Route>
            <Route path="/new">
              <Layout>
                <Newdesign />
              </Layout>
            </Route>
            <Route path="/shared/:id">
              <SharedHistoryWrapper />
            </Route>

            <Route path="/prompt">
              <Layout>
                <Prompt />
              </Layout>
            </Route>
            {/** Routing that take Sidebar with itself End */}
          </Switch>
        </Router>
      </TokenContext.Provider>
    </>
  );
}

export default App;
