import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const ICD10Search = ({ onCodeSelect }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);

    if (searchTerm.length > 2) {
      try {
        const response = await axios.get(
          `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${searchTerm}&maxList=500`
        );
        setSuggestions(response.data[3]); // Array of [code, description] pairs
      } catch (error) {
        console.error('Error fetching ICD-10 data:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (code, description) => {
    onCodeSelect({ code, description });
    setQuery('');
    setSuggestions([]);
  };

  const clearSearch = () => {
    setQuery('');
    setSuggestions([]);
  };

  return (
    <div className="relative flex items-center w-full">
      {/* Search Input */}
      <div className="relative flex items-center w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 border border-gray-300 rounded-md bg-white">
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute left-3 text-gray-500"
        />
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Search ICD-10 Code or Diagnosis"
          className="w-full pl-10 pr-10 py-2 text-sm text-gray-700 bg-transparent outline-none rounded-md"
        />
        {query && (
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute right-3 text-gray-500 cursor-pointer"
            onClick={clearSearch}
          />
        )}
      </div>

      {/* Suggestions Dropdown */}
      {suggestions.length > 0 && (
        <div className="absolute top-full left-0 mt-1 w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 bg-white shadow-lg rounded-md overflow-y-auto z-10" style={{ maxHeight: 'calc(7 * 2.5rem)' }}>
        <table className="w-full table-auto border-collapse border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Code
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {suggestions.map(([code, description]) => (
              <tr
                key={code}
                onClick={() => handleSelect(code, description)}
                className="hover:bg-gray-200 cursor-pointer"
              >
                <td className="border border-gray-300 px-4 py-2">{code}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {description}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={2} className="text-center text-sm text-gray-500 py-2">
                {/* Select any diagnosis to add */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      )}
    </div>
  );
};

export default ICD10Search;
