import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy, faEdit, faSave,faPlus ,faTrash,faTimes ,faDownload, faShareAlt, faSyncAlt
} from "@fortawesome/free-solid-svg-icons";
import Summary from "../../Assets/images/summary1.png";
import MeetingNotes from "../../Assets/images/whitepaper1.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Assets/images/loading.svg'
import Nothing from '../../Assets/images/nothing.svg'
import ShareHistory from './ShareHistory'; 
import moment from "moment";
import ICD10Search from '../Screen/ICD10Search';
const HistoryDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [showAddICDForm, setShowAddICDForm] = useState(false);
  const [showAddSNOMEDForm, setShowAddSNOMEDForm] = useState(false);
  const [showAddCPTForm, setShowAddCPTForm] = useState(false);
  const cptInputRef = useRef(null);

  // Refs for input fields to focus
const icdInputRef = useRef(null);
const snomedInputRef = useRef(null);
const [isSidebarOpen, setIsSidebarOpen] = useState(false); // New State for transcription
const [isBlurred, setIsBlurred] = useState(false);
  const [historyDetails, setHistoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generatedContent, setGeneratedContent] = useState(null);
  const [isEditingGenerated, setIsEditingGenerated] = useState(false);
  const [currentSlide, setCurrentSlide] = useState("Notes");
  const generatedPostRef = useRef(null);
  const [transcriptWidth, setTranscriptWidth] = useState(256); // Default width (equivalent to w-64)
const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (showAddICDForm && icdInputRef.current) {
      icdInputRef.current.focus();
    }
  }, [showAddICDForm]);
  const toggleSidebar = () => {
    if (window.innerWidth < 768) {
      // On mobile screens, ensure the sidebar covers the screen properly
      setTranscriptWidth(window.innerWidth);
    }
    setIsSidebarOpen(!isSidebarOpen);
    setIsBlurred(!isSidebarOpen); // Toggle the blur effect when opening/closing
  };
  
  // Effect to focus on SNOMED input when the form opens
  useEffect(() => {
    if (showAddSNOMEDForm && snomedInputRef.current) {
      snomedInputRef.current.focus();
    }
  }, [showAddSNOMEDForm]);
  const fetchHistoryDetails = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please login first.");
      return;
    }
    try {
      const response = await axios.get(
        `https://clinicalnotesapp.azurewebsites.net/speech_history/${id}/retrieve/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setHistoryDetails(response.data);

      // Set the default slide to "Notes" and set the generated content
      setCurrentSlide("Notes");
      setGeneratedContent({
        title: "Notes",
        content: response.data.notes,
        isHtmlContent: true,
      });

    } catch (error) {
      console.error("Error fetching history details:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setGeneratedContent(null);  // Reset the generated content when `id` changes
    fetchHistoryDetails();
  }, [id]);

  const switchSlide = (slide) => {
    setCurrentSlide(slide);

    if (slide === "Notes" && historyDetails.notes) {
      setGeneratedContent({
        title: "Notes",
        content: historyDetails.notes,
        isHtmlContent: true,
      });
    } else if (slide === "Summary" && historyDetails.summary) {
      setGeneratedContent({
        title: "Summary",
        content: historyDetails.summary,
        isHtmlContent: true,
      });
    } else {
      setGeneratedContent(null);
    }
  };

  const handleGenerateContent = async (type, title, isRegeneration = false) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Error", "Authentication required. Please login.", "error");
      return;
    }

    if (!isRegeneration && historyDetails[type]) {
      setGeneratedContent({
        type,
        title,
        content: historyDetails[type],
      });
      setIsEditingGenerated(false);
      return;
    }

    try {
      Swal.fire({
        title: `Generating ${title}...`,
        html: `<div class="relative flex items-center justify-center overflow-hidden mt-4">
                  <div class="w-16 h-16 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin"></div>
                  <div class="absolute flex items-center justify-center">
                    <div class="w-12 h-12 border-4 border-solid border-transparent border-t-black rounded-full animate-spin m-2"></div>
                    <div class="absolute w-8 h-8 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin m-2"></div>
                  </div>
                </div>`,
        allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
          popup: "w-96 h-64 flex flex-col items-start justify-start p-4",
          title: "text-lg",
          htmlContainer: "flex flex-col items-center justify-center w-full h-full",
        },
      });

      const apiUrls = {
        summary: "https://clinicalnotesapp.azurewebsites.net/generate_summary/",
        meeting_notes: "https://clinicalnotesapp.azurewebsites.net/generate_meeting_notes/",
      };

      const formData = new FormData();
      formData.append("text", historyDetails.text);
      formData.append("SpeechThread_id", id);

      const response = await axios.post(apiUrls[type], formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setHistoryDetails({
          ...historyDetails,
          [type]: response.data[type],
        });

        setGeneratedContent({
          type,
          title,
          content: response.data[type],
        });
        setIsEditingGenerated(true);
        Swal.close();
        scrollToGeneratedPost();
      }
    } catch (error) {
      console.error("Error generating content:", error);
      Swal.fire("Error", `Failed to generate ${title}. ${error.response ? error.response.data : "Please try again later."}`, "error");
    }
  };

  const handleSaveGeneratedText = async () => {
    if (generatedContent) {
      setIsEditingGenerated(false);
  
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          Swal.fire("Error", "Authentication required. Please login.", "error");
          return;
        }
  
     // Sanitize the content when saving, not during editing
     const sanitizedContent = generatedContent.content
     .replace(/<p><br><\/p>/g, '')  // Remove empty <p><br></p> tags
     .replace(/<p>/g, '')           // Remove <p> opening tags
     .replace(/<\/p>/g, '<br>')     // Replace closing <p> with <br>
     .replace(/(<br\s*\/?>){2,}/g, '<br>')  // Consolidate multiple <br> tags
     .replace(/<div>\s*<\/div>/g, '')       // Remove empty <div> tags
     .replace(/<div>/g, '')                 // Remove opening <div> tags
     .replace(/<\/div>/g, '<br>')           // Replace closing <div> with <br>
     .replace(/&nbsp;/g, ' ')               // Remove non-breaking spaces
     .replace(/<br>\s*<\/b><br>/g, '</b>')  // Remove extra <br> after bold text like "Subjective:" label
     .trim();  // Trim leading/trailing whitespace
  
        // Prepare the updated data to be sent to the backend
        const updateData = {
          ...historyDetails,  // Existing data from the current history details
          notes: sanitizedContent,  // Updated notes
          status: "Completed"  // Ensure the status is "Completed"
        };
  
        // Make the PUT request to save the data
        const response = await axios.put(
          `https://clinicalnotesapp.azurewebsites.net/speech_history/${id}/update/`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          }
        );
  
        if (response.status === 200) {
          toast.success("Content saved successfully!", {
            position: "top-right",
            autoClose: 500,
          });
          setHistoryDetails(response.data);  // Update the state with the latest data from the response
        } else {
          throw new Error("Failed to save content");
        }
      } catch (error) {
        console.error("Error saving generated content:", error);
        Swal.fire("Error", "Failed to save content. Please try again later.", "error");
      }
    }
  };
  

  const handleCopyContent = (content) => {
    // Create a temporary container to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the HTML content to be copied
    tempElement.style.position = "absolute";
    tempElement.style.left = "-9999px"; // Move it off-screen
  
    // Append the element to the body
    document.body.appendChild(tempElement);
  
    // Select the content inside the element
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  
    // Try to copy the content
    try {
      document.execCommand("copy");
      toast.success("Copied! The content has been copied to the clipboard.", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (err) {
      console.error("Failed to copy:", err);
      toast.error("Failed to copy the content. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    }
  
    // Remove the temporary element from the DOM
    document.body.removeChild(tempElement);
  };
  
  
  const handleMouseDown = () => {
    setIsResizing(true);
  };
  
  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = window.innerWidth - e.clientX;
      setTranscriptWidth(Math.max(200, Math.min(newWidth, 600))); // Set min and max widths
    }
  };
  
  const handleMouseUp = () => {
    setIsResizing(false);
  };
  
  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);
  

  const handleDownloadContent = async (title, content, speechThreadId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication required. Please login.");
      return;
    }
  
    try {
      // Use FormData for the request
      const formData = new FormData();
      formData.append('text', content);
      console.log("content : ", content)
      formData.append('SpeechThread_id', speechThreadId);
  
      // Make the POST request with FormData
      const response = await axios.post(
        'https://clinicalnotesapp.azurewebsites.net/generate_pdf/',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',  // Ensure correct content type
          },
          responseType: 'blob',  // Expect binary data
        }
      );
  
      // Check if the response is successful
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}.pdf`); // Specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  // Clean up the link element
  
        toast.success("PDF downloaded successfully!", {
          position: "top-right",
          autoClose: 500,
        });
      } else {
        throw new Error('Failed to generate PDF');
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.success("PDF downloaded successfully!", {
        position: "top-right",
        autoClose: 500,
      });
    }
  };
  
  const handleShareContent = (content, title) => {
    const plainTextContent = stripHtmlTags(content); // Clean the content
  
    if (navigator.share) {
      navigator.share({
        title: `Generated ${title}`,
        text: plainTextContent,  // Share only the cleaned text
      })
      .then(() => {
        toast.success("Content shared successfully!", {
          position: "top-right",
          autoClose: 500,
        });
      })
      .catch(err => {
        console.error("Failed to share:", err);
        toast.error("Failed to share the content. Please try again.", {
          position: "top-right",
          autoClose: 500,
        });
      });
    } else {
      Swal.fire("Error", "Web share is not supported on this browser.", "error");
    }
  };
  
  

  const stripHtmlTags = (html) => {
    // Create a temporary DOM element
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
  
    // Remove all style elements and scripts
    const stylesAndScripts = tmp.querySelectorAll("style, script");
    stylesAndScripts.forEach(el => el.remove());
  
    // Return just the plain text content without any HTML, styles, or scripts
    return tmp.textContent || tmp.innerText || "";
  };
  const formatTranscript = (text) => {
    // Check if the text contains any speaker labels
    const hasSpeakers = /(Speaker \d+:)/.test(text);
  
    if (!hasSpeakers) {
      // If no speakers are detected, return the plain text
      return <p className="p-2">{text}</p>;
    }
  
    // Split the text by matching "Speaker X:" and keep the speaker together with their dialogue
    const lines = text.split(/(Speaker \d+:)/).filter(Boolean);
  
    // Combine speaker lines with their following dialogue
    const formattedLines = [];
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith("Speaker")) {
        const speaker = lines[i];
        const dialogue = lines[i + 1] ? lines[i + 1].trim() : "";
  
        // Determine background color based on speaker
        const bgColor = speaker.includes("Speaker 1") ? "bg-[#f0f0f0]" : "bg-[#fffdd0]";
  
        formattedLines.push(
          
          <p key={i} className={`mt-2 p-2 rounded ${bgColor}`}>
            <span className="font-semibold"></span> {dialogue}
          </p>
        );
        i++; // Skip the next index since it's already used as dialogue
      }
    }
  
    return formattedLines;
  };
  
  
  
  

  const scrollToGeneratedPost = () => {
    setTimeout(() => {
      if (generatedPostRef.current) {
        generatedPostRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100);
  };
// Function to handle adding a new ICD code
const handleAddICDCode = (newCode) => {
  const updatedICD = [...historyDetails.icd10_codes, newCode];
  updateICDCodes(updatedICD);
};

// Function to delete an ICD code
const handleDeleteICDCode = (codeToDelete) => {
  const updatedICD = historyDetails.icd10_codes.filter(code => code.code !== codeToDelete.code);
  updateICDCodes(updatedICD);
};

// API call to update ICD codes
const updateICDCodes = async (updatedICD) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `https://clinicalnotesapp.azurewebsites.net/speech_history/${id}/update_icd_codes/`,
      updatedICD,
      { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      toast.success("ICD codes updated successfully!");
      setHistoryDetails({ ...historyDetails, icd10_codes: updatedICD });
    }
  } catch (error) {
    console.error("Failed to update ICD codes:", error);
    toast.error("Failed to update ICD codes.");
  }
};


// Function to handle adding a new SNOMED code
const handleAddSNOMEDCode = (newCode) => {
  const updatedSNOMED = [...historyDetails.diagnosis, newCode];
  updateSNOMEDCodes(updatedSNOMED);
};

// Function to delete a SNOMED code
const handleDeleteSNOMEDCode = (codeToDelete) => {
  const updatedSNOMED = historyDetails.diagnosis.filter(code => code.snomedcode !== codeToDelete.snomedcode);
  updateSNOMEDCodes(updatedSNOMED);
};
const handleAddCPTCode = (newCode) => {
  const updatedCPT = [...historyDetails.cpt_codes, newCode];
  updateCPTCodes(updatedCPT);
};

const handleDeleteCPTCode = (codeToDelete) => {
  const updatedCPT = historyDetails.cpt_codes.filter(
    (code) => code.code !== codeToDelete.code
  );
  updateCPTCodes(updatedCPT);
};

// API call to update SNOMED codes
const updateSNOMEDCodes = async (updatedSNOMED) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `https://clinicalnotesapp.azurewebsites.net/speech_history/${id}/update_diagnosis/`,
      updatedSNOMED,
      { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      toast.success("SNOMED codes updated successfully!");
      setHistoryDetails({ ...historyDetails, diagnosis: updatedSNOMED });
    }
  } catch (error) {
    console.error("Failed to update SNOMED codes:", error);
    toast.error("Failed to update SNOMED codes.");
  }
};
const updateCPTCodes = async (updatedCPT) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `https://clinicalnotesapp.azurewebsites.net/speech_history/${id}/update_pcs_codes/`,
      updatedCPT,
      { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      toast.success("CPT codes updated successfully!");
      setHistoryDetails({ ...historyDetails, cpt_codes: updatedCPT });
    }
  } catch (error) {
    console.error("Failed to update CPT codes:", error);
    toast.error("Failed to update CPT codes.");
  }
};


  if (loading) {
    return  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <div style={{ textAlign: 'center' }}>
      <div>Loading...</div>
      <div>
        <img src={Loading} alt="Loading" />
      </div>
    </div>
  </div>;
  }

  if (!historyDetails) {
    return  <div className="flex items-center justify-center h-screen">
    <div className="text-center">
        <img className="w-1/2 h-1/2 mx-auto" src={Nothing} alt="Loading" />
        <div>No History detail found</div>
    </div>
</div>

;
  }

  return (
    <div className="container mx-auto h-screen flex">
    {/* Main Content */}
    <div className={`flex-1 transition-all duration-300`} style={{ marginRight: isSidebarOpen ? `${transcriptWidth}px` : "0" }}>
      <div className="flex flex-col items-center mt-0 lg:py-12 bg-[#E8ECF4] h-full">
        <div className="space-y-1 mx-2 w-full  px-0 ">
        <div className="flex items-center justify-end w-full ">

          {/* <ShareHistory speechThreadId={id} /> */}
          <div className="flex items-center justify-end mt-0 lg:-mt-6 space-x-2" style={{ zIndex: 20 }}>
                  {isEditingGenerated ? (
                    <button
                      onClick={handleSaveGeneratedText}
                       title="Save"
                      className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                    >
                      <FontAwesomeIcon icon={faSave} className="" />
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsEditingGenerated(true)}
                        title="Edit"
                      className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                    >
                      <FontAwesomeIcon icon={faEdit} className="" />
                    </button>
                  )}

                  <button
                    onClick={() => handleCopyContent(generatedContent.content)}
                      title="Copy"
                    className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                  >
                    <FontAwesomeIcon icon={faCopy} className="" />
                  </button>
                  <button
                    onClick={() => handleDownloadContent(generatedContent.title, generatedContent.content, id)}
                      title="Download"
                    className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                  >
                    <FontAwesomeIcon icon={faDownload} className="" />
                  </button>
                  {/* <button
                    onClick={() => handleShareContent(generatedContent.content, generatedContent.title)}
                      title="Share"
                    className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                  >
                    <FontAwesomeIcon icon={faShareAlt} className="" />
                  </button> */}
          <ShareHistory speechThreadId={id} />

                  {/* <button
                    onClick={() => handleGenerateContent(generatedContent.type, generatedContent.title, true)}
                    className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                  >
                    <FontAwesomeIcon icon={faSyncAlt} className="" />
                  </button> */}
                  <button
              onClick={toggleSidebar}
              
              title="Transcript"
              className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
            >
              <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faPlus} />
            </button>
                </div> 
                
                </div>
                
          <div className="text-left w-full  pt-12 ">

            <h1 className="-mt-2 lg:-mt-14 text-base ml-4 font-bold" style={{ zIndex: 15 }}>{historyDetails.title}</h1>
            <div className="flex mt-2 ml-4">

            <p className="text-xs text-black border-2  rounded-full  px-2">
            <strong >{historyDetails.patient_name}</strong> 
            </p>
            <p className="text-xs text-black border  rounded-full  px-2">
            {/* <strong>D.O.B : </strong> 2 May 1975 */}
            <strong> D.O.B : </strong>{historyDetails.patient_date_of_birth ? historyDetails.patient_date_of_birth : "Null"}
            </p>
            <p className="text-xs text-black border  rounded-full  px-2">
              {/* {new Date(historyDetails.created_at).toLocaleDateString()} {new Date(historyDetails.created_at).toLocaleTimeString()} */}
              {moment(historyDetails.created_at).format("MMM DD, YYYY hh:mm A")} 
            </p>
            </div>
            {/* <hr className=" border-t border-gray-400 " /> */}
          </div>

{/* 
          {historyDetails && (
  <div className=" relative p-4 bg-white rounded-2xl shadow-md">
    <h3 className="text-lg font-bold text-left mb-4">Transcription Text</h3>
    <div className="bg-[#E8ECF4] p-4 max-h-[30vh] overflow-y-auto rounded-lg scrollbar-custom">
      <p>{historyDetails.text}</p>
    </div>
  </div>
)} */}



          <div className="hidden relative flex justify-center mb-6">
            <button
              className={`px-2 py-2 text-base font-bold rounded-l-lg ${
                currentSlide === "Notes"
                  ? "bg-[#F2911B] text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => switchSlide("Notes")}
            >
              Notes
            </button>
            <button
              className={`px-2 py-2 text-base font-bold rounded-r-lg ${
                currentSlide === "Summary"
                  ? "bg-[#F2911B] text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => switchSlide("Summary")}
            >
              Summary
            </button>
          </div>

          {generatedContent && (
            <div
              className="relative bg-white   px-4 mb-6 overflow-hidden w-full "
              ref={generatedPostRef}
            >
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <p className=""></p>
                </div>
               
              </div>

              <div className="py-0 pb-4 px-4 rounded-lg overflow-auto text-sm">
                {isEditingGenerated ? (
                  <ReactQuill
                    value={generatedContent.content}
                    onChange={(content) => setGeneratedContent({ ...generatedContent, content })}
                  />
                ) : (
                  <div
                  className="prose max-w-none leading-tight mt-4 space-y-0"
                  style={{
                    color: 'black', // Set all text to black
                  }}
                  dangerouslySetInnerHTML={{
                    __html: generatedContent.content.replace(
                      /<(b|strong)>/g,
                      '<$1 style="color: black; font-weight: bold;">'
                    ),
                  }}
                />
              )}
            </div>
            </div>
          )}
<div className="">

<ICD10Search onCodeSelect={handleAddICDCode} />
</div>
{/* ICD-10 Codes Section */}
<div className="bg-white px-8 py-4  mb-6">
  {/* <div className="flex justify-between">
    <h3 className="text-base font-bold mb-2">ICD-10 Codes</h3>
    <button
      onClick={() => setShowAddICDForm(!showAddICDForm)}
      className="text-[#F2911B] hover:text-black"
    >
      {showAddICDForm ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} />}
    </button>
  </div> */}

  {historyDetails && historyDetails.icd10_codes && historyDetails.icd10_codes.length > 0 ? (
    <ul>
      {historyDetails.icd10_codes.map((code, index) => (
        <li key={index} className="flex justify-between items-center">
          <div>
            <strong>{code.code}</strong>: {code.description}
          </div>
          <button
            onClick={() => handleDeleteICDCode(code)}
            className="text-[#F2911B] hover:text-black "
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </li>
      ))}
    </ul>
  ) : (
    <p>No ICD-10 codes available.</p> // Message when no codes
  )}

  {/* Conditionally show the form to add a new ICD code */}
  {showAddICDForm && (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const newCode = { code: e.target.code.value, description: e.target.description.value };
        handleAddICDCode(newCode);
        setShowAddICDForm(false); // Hide form after adding
      }}
      className="mt-4"
    >
      <input
        name="code"
        placeholder="Code"
        required
        ref={icdInputRef} // Ref to focus this input
        className="border rounded-xl p-2 mr-2"
      />
      <input
        name="description"
        placeholder="Description"
        required
        className="border rounded-xl p-2 mr-2"
      />
      <button
        type="submit"
        className="text-[#F2911B] hover:text-black border border-[#F2911B] px-2 py-1 rounded-xl"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </form>
  )}
</div>

{/* SNOMED Codes Section */}
<div className="bg-white px-8 py-4 hidden  mb-6">
  <div className="flex justify-between">
    <h3 className="text-base font-bold mb-2">Diagnosis</h3>
    <button
      onClick={() => setShowAddSNOMEDForm(!showAddSNOMEDForm)}
      className="text-[#F2911B] hover:text-black"
    >
      {showAddSNOMEDForm ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} />}
    </button>
  </div>

  {historyDetails && historyDetails.diagnosis && historyDetails.diagnosis.length > 0 ? (
    <ul>
      {historyDetails.diagnosis.map((diag, index) => (
        <li key={index} className="mb-2 flex justify-between items-center">
          <div>
            <strong>{diag.snomedcode}</strong>: {diag.description}
            <p className="mt-1">
              <strong>Note: </strong>{diag.note}
            </p>
            {diag.laterality && (
              <p className="mt-1">
                <strong>Laterality: </strong>{diag.laterality}
              </p>
            )}
          </div>
          <button
            onClick={() => handleDeleteSNOMEDCode(diag)}
            className="text-[#F2911B] hover:text-black"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </li>
      ))}
    </ul>
  ) : (
    <p>No diagnosis available.</p> // Message when no diagnosis
  )}

  {/* Conditionally show the form to add a new SNOMED code */}
  {showAddSNOMEDForm && (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const newCode = {
          snomedcode: e.target.snomedcode.value,
          description: e.target.description.value,
          note: e.target.note.value,
          laterality: e.target.laterality.value,
        };
        handleAddSNOMEDCode(newCode);
        setShowAddSNOMEDForm(false); // Hide form after adding
      }}
      className="mt-4"
    >
      <input
        name="snomedcode"
        placeholder="SNOMED Code"
        required
        ref={snomedInputRef} // Ref to focus this input
        className="border p-2 mr-2 rounded-xl"
      />
      <input
        name="description"
        placeholder="Description"
        required
        className="border p-2 mr-2 rounded-xl"
      />
      <input
        name="note"
        placeholder="Note"
        className="border p-2 mr-2 rounded-xl"
      />
      <input
        name="laterality"
        placeholder="Laterality"
        className="border p-2 mr-2 rounded-xl"
      />
      <button
        type="submit"
        className="text-[#F2911B] hover:text-black border border-[#F2911B] px-2 py-1 rounded-xl"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </form>
  )}
</div>



{/*  CPT Codes Section */}
<div className="bg-white px-8 py-4 mb-6">
  <div className="flex justify-between">
    <h3 className="text-base font-bold mb-2">CPT Codes</h3>
    <button
      onClick={() => setShowAddCPTForm(!showAddCPTForm)}
      className="text-[#F2911B] hover:text-black"
    >
      {showAddCPTForm ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} />}
    </button>
  </div>

  {historyDetails && historyDetails.cpt_codes && historyDetails.cpt_codes.length > 0 ? (
    <ul>
      {historyDetails.cpt_codes.map((code, index) => (
        <li key={index} className="flex justify-between items-center">
          <div>
            <strong>{code.code}</strong>: {code.description}
          </div>
          <button
            onClick={() => handleDeleteCPTCode(code)}
            className="text-[#F2911B] hover:text-black "
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </li>
      ))}
    </ul>
  ) : (
    <p>No CPT codes available.</p>
  )}

  {showAddCPTForm && (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const newCode = { code: e.target.code.value, description: e.target.description.value };
        handleAddCPTCode(newCode);
        setShowAddCPTForm(false);
      }}
      className="mt-4"
    >
      <input
        name="code"
        placeholder="Code"
        required
        ref={cptInputRef}
        className="border rounded-xl p-2 mr-2"
      />
      <input
        name="description"
        placeholder="Description"
        required
        className="border rounded-xl p-2 mr-2"
      />
      <button
        type="submit"
        className="text-[#F2911B] hover:text-black border border-[#F2911B] px-2 py-1 rounded-xl"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </form>
  )}
</div>


        </div>
      </div> </div>
   <div
  className={`fixed top-0 right-0 h-full p-4 transition-transform duration-300 overflow-y-auto scrollbar-custom ${
    isSidebarOpen ? "translate-x-0" : "translate-x-full"
  }`}
  style={{
    width: `${transcriptWidth}px`,
    maxWidth: "80vw", // Ensure it doesn't take up too much space on smaller screens
   // Ensure the sidebar stays on top of the blur effect
    zIndex: 10,
    backgroundColor: "#fff", // Set a solid background color to make the content clear
  }}
>

  <div className="flex items-center justify-between mb-4">
    <h3 className="text-lg font-bold">Transcript</h3>
    <button onClick={toggleSidebar} className="text-[#F2911B] hover:text-black">
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>
  <div>
    {historyDetails?.text
      ? formatTranscript(historyDetails.text)
      : "No transcript available."}
  </div>
  <div
    className="absolute top-0 left-0 h-full w-1 cursor-ew-resize"
    onMouseDown={handleMouseDown}
    style={{ backgroundColor: "#E8ECF4" }}
  />
</div>




    </div>
  );
};

export default HistoryDetails;
