import { useHistory , useLocation } from 'react-router-dom'; 
import React, { useState, useEffect } from 'react';
import axios from "axios";
const FhirRedirect = () => {
  const history = useHistory();


  const location = useLocation();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // get the iss and launch from url
    const iss = searchParams.get('iss');
    const launch = searchParams.get('launch');
    console.log("iss", iss);
    console.log("launch", launch);
    // if any of them is not present, alert the user and ask to try again
    if (iss && launch) {
      localStorage.setItem('iss', iss);
      const data= new URLSearchParams();
      data.append('iss', iss);
      data.append('launch', launch);

    
    axios.post(`https://clinicalnotesapp.azurewebsites.net/fhir_auth_url/`, data,
      
    ) 
      .then((response) => {
        // if the response is success, show the data  
        console.log(response);
        const url = response.data.url;
        // redirect to the url
        window.location.href = url;
      })
      .catch((error) => {
        // if the response is error, show the error
        console.log(error);
        alert('Error', error);
      })
      .finally(() => {
        // set loader to false
        setLoader(false);
      });

    }
  }, []);


  return (
    <div className="relative flex items-center justify-center min-h-screen ">

      <div className="text-center">
        <h1
          className="text-6xl font-bold mb-6"
        
        >
          Loading Application...
        </h1>

      </div>
    </div>
  );
};

export default FhirRedirect;