// FhirCallback.js

import React, { useEffect, useRef, useState } from "react";
import { useLocation  , useHistory} from "react-router-dom";
import axios from "axios";

const FhirCallback = () => {
  const location = useLocation();
  const history = useHistory();
  const effectRan = useRef(false);

  // State variables to hold patient and encounter data
  const [patientData, setPatientData] = useState(null);
  const [encounterData, setEncounterData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (effectRan.current) return; // Prevent the effect from running twice
    effectRan.current = true;

    const performAuthAndFetch = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const iss = localStorage.getItem("iss");

      console.log("code", code);
      console.log("state", state);
      console.log("iss", iss);
      if (code && iss) {
        try {
          await exchangeAuthCodeForToken(code, iss, state);
          await getPatientData(iss);
          await getEncounterData(iss);
          setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error("Error during authorization and data fetching:", error);
          alert("An error occurred. Please try again.");
          setLoading(false);
        }
      } else {
        const error = searchParams.get("error");
        console.error("Authorization error:", error);
        alert("Authorization failed. Please try again.");
        setLoading(false);
      }
    };

    performAuthAndFetch();
  }, [location.search]);

  const exchangeAuthCodeForToken = async (code, iss, state) => {
    try {
      const data = new URLSearchParams();
      data.append("code", code);
      data.append("state", state);
      data.append("iss", iss);

      console.log("data", data.toString());

      // Send POST request to backend for token exchange
      const response = await axios.post(
        "https://clinicalnotesapp.azurewebsites.net/fhir_token_endpoint/",
        data
      );

      // Handle token response from backend
      const tokenData = response.data.token_data;
      console.log("Token Data:", tokenData);



      localStorage.setItem("tokenData", JSON.stringify(tokenData));
      history.push("/athena-record")
      // history.push("/iframe")

    } catch (error) {
      console.error("Token exchange error:", error);
      alert("Failed to exchange authorization code for access token.");
    }
  };

  const getPatientData = async (iss) => {
    try {
      const tokendata = JSON.parse(localStorage.getItem("tokenData"));
      console.log("tokendata", tokendata);

      const token = tokendata.access_token;
      const patient = tokendata.patient;
      console.log("patient", patient);

      const patientDataPayload = new URLSearchParams();
      patientDataPayload.append("patient_id", patient);
      patientDataPayload.append("access_token", token);
      patientDataPayload.append("iss", iss);
      console.log("patientDataPayload", patientDataPayload.toString());

      // Fetch patient data
      const patientDataResponse = await axios.post(
        "https://clinicalnotesapp.azurewebsites.net/fhir_patient_view/",
        patientDataPayload
      );
      console.log("patientDataResponse", patientDataResponse);
      setPatientData(patientDataResponse.data); // Store data in state
    } catch (error) {
      console.error("Error occurred while fetching patient data", error);
      alert("Error occurred while fetching patient data");
    }
  };

  const getEncounterData = async (iss) => {
    try {
      const tokendata = JSON.parse(localStorage.getItem("tokenData"));
      console.log("tokendata", tokendata);

      const token = tokendata.access_token;
      const encounter = tokendata.encounter;
      console.log("encounter", encounter);

      const encounterDataPayload = new URLSearchParams();
      encounterDataPayload.append("encounter_id", encounter);
      encounterDataPayload.append("access_token", token);
      encounterDataPayload.append("iss", iss);
      console.log("encounterDataPayload", encounterDataPayload.toString());

      // Fetch encounter data
      const encounterDataResponse = await axios.post(
        "https://clinicalnotesapp.azurewebsites.net/fhir_encounter_view/",
        encounterDataPayload
      );
      console.log("encounterDataResponse", encounterDataResponse);
      setEncounterData(encounterDataResponse.data); // Store data in state
    } catch (error) {
      console.error("Error occurred while fetching encounter data", error);
      alert("Error occurred while fetching encounter data");
    }
  };

  return (
    <div>
      {loading ? (
        <h1>Completing Authorization...</h1>
      ) : (
        <div>
          {/* Display Patient Data */}
          {patientData ? (
            <div>
              <h1>Patient Data</h1>
              <pre>{JSON.stringify(patientData, null, 2)}</pre>
            </div>
          ) : (
            <p>Patient data not available.</p>
          )}

          {/* Display Encounter Data */}
          {encounterData ? (
            <div>
              <h1>Encounter Data</h1>
              <pre>{JSON.stringify(encounterData, null, 2)}</pre>
            </div>
          ) : (
            <p>Encounter data not available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FhirCallback;
